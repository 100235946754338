<template>
    <div>
         <!-- <HomeCarousel :items="items"/> -->
      <section id="slider-fixed">
			<div class="fix-banner">
				<div class="carousel-caption">
					<div class="container">
						<div class="slider-search-2">
							<h1>Encuentra el hogar de tus sueños</h1>
							<SearchFormHome/>
							<!--<div class="advance-page">
								<a href="property_search.html" class="btn btn-primary">Advance Search</a>
							</div>
							<span>We have listed over 100000+ property in our database</span>-->
						</div>
					</div>
				</div>
			</div>
		</section>
        
        <div class="main pb-1">
            <div class="row">
          </div>    
          <div class="container wow zoomIn">
            <h1 class="section-title "> ÚLTIMAS<span style="color:#cc2922;"> PROPIEDADES EN VENTA</span></h1>  
          </div>
        </div>
        <div class=" section-title text-center wow zoomIn">      
        <div class="container">
          <div id="property-listing" class="grid-style1 clearfix col-md-12">
            <Preloader v-if="!properties"/>
            <PropertyCard  
              v-else
              v-for="(item, i) in properties"
              :key="item.folio"
              :name="item.folio"
              :index="i"
              :item="item" 
            />
          </div>	
        </div>	
         <router-link class="btnmore" to="/propiedades?pagina=1">Ver más propiedades</router-link>
      </div>     
    </div>
    
    
</template>

<script>
import { mapGetters } from "vuex";
import SearchFormHome from "@/components/search/SearchFormHome.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import HomeCarousel from "@/components/carousel/HomeCarousel.vue";
import Preloader from "@/components/layout/Preloader.vue";

export default {
  components: {
    HomeCarousel,
    SearchFormHome,
    PropertyCard,
    Preloader
  },
  computed: {
    ...mapGetters({
      items:'_getHomeSlide',
      properties:'_getHomeProperties',
      error:'_getError',
    }),
  },
  created(){
    this.$store.dispatch('getHomeSlide');
    this.$store.dispatch('getHomeProperties');
  }
};
</script>

<style>
.listing-item {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 10px;
}
#propImg {
  width: 100%;
  height: 100%;
}

.navbar{
    height: 100% !important;
    width: 100% !important;

}
.highlight-props:before, .highlight-props:before, .highlight-props:after, .highlight-props:after {
  content: " ";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  height: 1px;
  background-color: #d79b2b;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
}
#slider-fixed {
  background: url(/images/backParallax.jpg) no-repeat fixed;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
  background-size: cover;
  background-position: 50%;
  height: 80vh;
  position: relative;
}
.slider-search-2 h1 {
  padding: 15px 30px;
  display: table;
  font-size: 24px;
  position: relative;
  background: #000;
}

.slider-search-2 h1:before {

    border-left: 0 solid transparent;
    border-right: 25px solid transparent;
    content: "";
    height: 0;
    left: 20px;
    position: absolute;
    right: 100%;
    bottom: -30px;
    -moz-transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
    width: 0;

}
.slider-search-2 h1:before {
  border-top: 35px solid #000;
}
.slider-search-2 h1 {
  font-size: 24px;
  font-family: 'Montserrat' !important;
}
#slider-fixed .carousel-caption {
  left: 0;
  top: 45%;
  width: 100%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.slider-search-2 {
  width: 100%;
}
.section-title{
  font-family: 'Montserrat';
  color: #000;
}
</style>
