<template>
  <main>
    <Preloader v-if="!prop" />
    <div v-else>
      <Banner
        :image="prop.images[0].largefile"
        :pageTitle="
          activeLocale == 'es' || !prop.propertyNameEn
            ? prop.propertyNameEs
            : prop.propertyNameEn
        "
      />
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="main-2 col-sm-8">

              <div class="property-single-item property-main mt-5">

  <!-- PROPERTY TITLE -->
              <!-- <h1 v-if="prop.propertyNameEs" class="property-title mt-title text-black">
                {{ prop.propertyNameEs }}
                <small v-if="prop.address">{{ prop.city}}</small>
              </h1> -->
              <!-- BEST INFORMATION -->
              <!-- <div class="property-topinfo">
                <ul
                  v-if="
                    prop.bedrooms != '0' ||
                      prop.bathrooms != '0' ||
                      prop.m2c != '0' ||
                      prop.toperation != '0'
                  "
                  class="amenities"
                >
                  <li v-if="prop.typepropertyEs">
                    <i class="icon-house"></i> {{ prop.typepropertyEs }}
                  </li>
                  <li v-if="prop.m2c">
                    <i class="icon-area"></i> {{ prop.m2c }} m<sup>2</sup>
                  </li>
                   <li v-if="prop.mlot">
                    <i class="icon-area"></i> {{ prop.mlot }} m<sup>2</sup>
                  </li>
                  <li v-if="prop.bedrooms">
                    <i class="icon-bedrooms"></i> {{ prop.bedrooms }}
                  </li>
                  <li v-if="prop.bathrooms">
                    <i class="icon-bathrooms"></i> {{ prop.bathrooms }}
                  </li>
                </ul>
                <div v-if="prop.folio != '0'" id="property-id" class="folio">
                 Folio: # {{ prop.folio }}
                </div>
              </div> -->

              <!-- PRICE  -->

          
<!--                
                    <DetailCarousel2 :items="prop.images" /> -->
              <!-- CAROUSEL -->
              <DetailCarousel :items="prop.images" />
              <br />

              </div>
            

              	<div class="detail_text">
	
									<div class="property-text">
	
										<h4 class="property-tit3le">{{ prop.propertyNameEs }}</h4>
                      <ul class="detailsdd">
                        <li>
                          	<span><i class="fa fa-map-marker" aria-hidden="true"></i> <span v-if="prop.colony != 0"> {{prop.colony}}   </span>{{ prop.city }} </span>
                        </li>
                        <li>
                          <span v-if="prop.address != 0"><i class="fa fa-map-marker" aria-hidden="true"></i> Dirección: {{ prop.address }} </span>
                        </li>
                        <li>
                          <span v-if="prop.referenciaEsp != 0"><i class="fa fa-map-marker" aria-hidden="true"></i> Referencia: {{ prop.referenciaEsp }} </span>	
                        </li>
                      </ul>																		
	
                        <div  class="price-here">
                          <h3>
                            <span v-if="prop.prices.sold!='0'">
                               EN {{prop.operation.sold}}    {{ prop.prices.sold }} 
                              </span>
                              <span v-else-if="prop.prices.opportunity_rent !='0'">
                              EN  {{prop.operation.opportunity_rent}} {{  prop.prices.opportunity_rent }} {{  prop.currennamePerMonth }}
                              </span>
                              <span v-else-if="prop.prices.rent !='0'">
                               EN {{prop.operation.rent}}  {{  prop.prices.rent }} {{  prop.currennamePerMonth }}
                              </span>
                              <span v-else-if="prop.prices.vacation_rent !='0'">
                              EN  {{prop.operation.vacation_rent}} {{  prop.prices.vacation_rent }} {{  prop.vacation_rental_currency }}
                              </span>
                            </h3> 
                            <b  id="leyendaPrecios">
                              *PRECIOS SUJETOS A CAMBIOS SIN PREVIO AVISO</b>
                        </div>

                        <div id="property-detail-wrapper" class="style1">
               
                
              </div>
									</div>
	
								</div>
                      <div class="bg-white p-3 mt-5 mb-5">
                        
                        <div class="social_areaProp">                           
                           <ul>												
                              <li>Folio : <span style="color:#cc2922"> #{{prop.folio}} </span></li>
                              <li>Area: <span style="color:#cc2922">{{prop.m2c}} m<sup>2</sup></span></li>
                              <li>Recamaras : <span style="color:#cc2922">{{prop.bedrooms}}</span></li>
                              <li>Baños : <span style="color:#cc2922">{{prop.bathrooms}}</span></li>							
                              <li>Tipo de propiedad : <span style="color:#cc2922">{{prop.typepropertyEs}}</span></li>
                           </ul>		                           	
                        </div>
                      </div>
                      <!-- PROPERTY FLAYERS -->
                      <div class="bg-white p-3">
                          <!-- BEING IMMO CONTENT --> 
                        <h2> <b>{{ $t("pages.property_detail.share_title") }} </b></h2>
                       
                        <div>
                           <h4 style="color:#000;">Banners</h4>
                           <ul class="buttons-content">												
                              <li v-if="prop.banner1">												
                                 <a class="banner-button" target="_blank" :href="prop.banner1">
                                    <i class="fa fa-file-image-o"></i> 1 Foto
                                 </a>
                              </li>
                              <li v-if="prop.banner3">
                                 <a class="banner-button" target="_blank" :href="prop.banner3">
                                    <i class="fa fa-file-image-o"></i> 3 Fotos
                                 </a>
                              </li>	
                              <li v-if="prop.banner4">												
                                 <a class="banner-button" target="_blank" :href="prop.banner4">
                                    <i class="fa fa-file-image-o"></i> 4 Fotos
                                 </a>
                              </li>
                              <li v-if="prop.banner6">												
                                 <a class="banner-button" target="_blank" :href="prop.banner6">
                                    <i class="fa fa-file-image-o"></i> 6 Fotos
                                 </a>
                              </li>		
                           </ul>	

                           <br>
                           <h4 v-if="prop.flyerEs || prop.flyerEn" style="color:#000;">FLYERS</h4>
                           <ul class="buttons-content">												
                              <li v-if="prop.flyerEs">												
                                 <a class="banner-button" target="_blank" :href="prop.flyerEs">
                                    <i class="fa fa-file-pdf-o"></i> FLYER ESPAÑOL
                                 </a>
                              </li>
                              <li v-if="prop.flyerEn">												
                                 <a class="banner-button" target="_blank" :href="prop.flyerEn">
                                    <i class="fa fa-file-pdf-o"></i> FLYER INGLES
                                 </a>
                              </li>		
                           </ul>			
                        </div>
                        <br>   
                        <br>
                      </div>

                      <div class="bg-white p-3 mt-5">
                           <h2> <b>COMPARTE EN REDES SOCIALES</b></h2>
                        
                        <div class="social_areaProp">                           
                           <ul>												
                              <li><a class="facebook" data-toggle="tooltip" data-placement="top" title="Facebook"   :href="
                        `https://www.facebook.com/sharer/sharer.php?u=${url}`
                      " target="_blank"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
                              <li><a class="twitter" data-toggle="tooltip" data-placement="top" title="Twitter"   :href="`https://twitter.com/home?status=${url}`" target="_blank"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
                              <li><a class="linkedin" data-toggle="tooltip" data-placement="top" title="Linkedin" 
                              :href="`https://www.linkedin.com/sharing/share-offsite/?url=${url}`" target="_blank"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
                              <li><a class="gmas" data-toggle="tooltip" data-placement="top" title="Google Plus" 
                              :href="`https://plus.google.com/share?url=${url}`" target="_blank"><i class="fab fa-google-plus" aria-hidden="true"></i></a></li>							
                              <li><a class="pinterest" data-toggle="tooltip" data-placement="top" title="Pinterest" 
                              :href="`https://www.pinterest.com.mx/pin/create/button/?url=${url}`" target="_blank" ><i class="fab fa-pinterest" aria-hidden="true"></i></a></li>
                              <span class="whatsButton">
                                 <h4>CONTÁCTANOS POR WHATSAPP
                                    <a class="contact-links" data-toggle="tooltip" data-placement="top"                       :href="
                        `https://api.whatsapp.com/send?text=${$t(
                          'pages.property_detail.share_whats_message_public'
                        )}: ${url}`
                      " target="_blank">
                                       <i style="float:none; margin-left:15px;font-size:25px;line-height:40px;" class="fab fa-whatsapp"></i>
                                    </a> 
                                 </h4>										 									
                              </span>	
                           </ul>		                           	
                        </div>
                      </div>
                           <!-- BEING SHARE IN SOCIAL NETWORK --> 
                       
                        <br>  
                        <br>
                        <!-- END SHARE IN SOCIAL NETWORK --> 

                        

             

               
                        <!-- BEING DESCRIPTION --> 
                        <h2><b> {{ $t("pages.property_detail.description_prop") }} </b></h2>

                         <button v-if="prop.descriptionEs != '0'" class="btn btn-descrip btn-block text-left mt-5" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <span class="text-left">ESPAÑOL </span><span class="text-right"><i aria-hidden="true" class="fa fa-angle-right"></i></span>
                          </button>
                            <div v-if="prop.descriptionEs != '0'" class="collapse" id="collapseExample">
                              <div class="card card-body">
                                 <p>    
                                    <pre> {{ prop.descriptionEs }}</pre> 
                                </p> 
                              </div>
                            </div>
                          <button v-if="prop.descriptionEn != '0'" class="btn btn-descrip btn-block text-left mt-5" type="button" data-toggle="collapse" data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2">
                            <span class="text-left">INGLÉS </span><span class="text-right"><i aria-hidden="true" class="fa fa-angle-right"></i></span>
                          </button>
                            <div v-if="prop.descriptionEn != '0'" class="collapse " id="collapseExample2">
                              <div class="card card-body">
                                 <p>    
                                    <pre> {{ prop.descriptionEn }}</pre> 
                                </p> 
                              </div>
                            </div>
                           

                        
                            
                      


                      
                            
                  <br><br>
              <!-- AMENITIES -->
            <h2 class="mt-5 "> <b>{{ $t("pages.property_detail.amenities_prop") }} </b></h2>
              <button v-if="prop.interiorAmenities != '0'" class="btn btn-descrip btn-block text-left mt-5" type="button" data-toggle="collapse" data-target="#collapseExamplea" aria-expanded="false" aria-controls="collapseExamplea">
                <span class="text-left">INTERIORES </span><span class="text-right"><i aria-hidden="true" class="fa fa-angle-right"></i></span>
              </button>
                <div v-if="prop.interiorAmenities!= '0'" class="collapse" id="collapseExamplea">
                  <div class="card card-body">
                      <p>    
                        <ul class="property-amenities-list">

                           <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in prop.interiorAmenities"
                          :index="i"
                          :key="i"
                          class="mb-3 mr-3 enabled"
                        >
                          <span><i class="icon-check"></i></span>
                          {{ item }}
                        </li>
                      </ul>
                        </ul>
                    </p> 
                  </div>
                </div>

              <button v-if="prop.exteriorAmenities != '0'" class="btn btn-descrip btn-block text-left mt-5" type="button" data-toggle="collapse" data-target="#collapseExampleex" aria-expanded="false" aria-controls="collapseExampleex">
                <span class="text-left">EXTERIORES </span><span class="text-right"><i aria-hidden="true" class="fa fa-angle-right"></i></span>
              </button>
                <div v-if="prop.exteriorAmenities!= '0'" class="collapse" id="collapseExampleex">
                  <div class="card card-body">
                      <p>    
                        <ul class="property-amenities-list">

                           <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in prop.exteriorAmenities"
                          :index="i"
                          :key="i"
                          class="mb-3 mr-3 enabled"
                        >
                          <span><i class="icon-check"></i></span>
                          {{ item }}
                        </li>
                      </ul>
                        </ul>
                    </p> 
                  </div>
                </div>
              <button v-if="prop.developmentServices	 != '0'" class="btn btn-descrip btn-block text-left mt-5" type="button" data-toggle="collapse" data-target="#collapseExampledev" aria-expanded="false" aria-controls="collapseExampledev">
                <span class="text-left">DESARROLLOS </span><span class="text-right"><i aria-hidden="true" class="fa fa-angle-right"></i></span>
              </button>
               <div v-if="prop.developmentServices!= '0'" class="collapse" id="collapseExampledev">
                  <div class="card card-body">
                      <p>    
                        <ul class="property-amenities-list">

                           <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in prop.developmentServices"
                          :index="i"
                          :key="i"
                          class="mb-3 mr-3 enabled"
                        >
                          <span><i class="icon-check"></i></span>
                          {{ item }}
                        </li>
                      </ul>
                        </ul>
                    </p> 
                  </div>
                </div>

              <!-- <div
                v-if="
                  prop.interiorAmenities ||
                    prop.otherAmenities ||
                    prop.exteriorAmenities ||
                    prop.developmentAmenities ||
                    prop.developmentServices ||
                    prop.services
                "
              >
                <h2 class="text-center">
                  
                </h2>
                <div class=" mt-2 mb-2">
                  <ul class="property-amenities-list">
                  
                    <li v-if="prop.interiorAmenities || prop.otherAmenities">
                      <h4>
                        {{ $t("pages.property_detail.amenities_interior") }}
                      </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in prop.interiorAmenities"
                          :index="i"
                          :key="i"
                          class="mb-3 mr-3 enabled"
                        >
                          <span><i class="icon-check"></i></span>
                          {{ item }}
                        </li>
                      </ul>
                      <ul v-if="prop.otherAmenities" class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in prop.otherAmenities"
                          :index="i"
                          :key="i"
                          class="enabled"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </li>
                   
                    <li v-if="prop.exteriorAmenities">
                      <h4>
                        {{ $t("pages.property_detail.amenities_exterior") }}
                      </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in prop.exteriorAmenities"
                          :index="i"
                          :key="i"
                          class="enabled"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </li>

             
                    <li v-if="prop.developmentAmenities">
                      <h4>
                        {{ $t("pages.property_detail.amenities_development") }}
                      </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in prop.developmentAmenities"
                          :index="i"
                          :key="i"
                          class="enabled"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </li>

                    <li v-if="prop.developmentServices || prop.services">
                      <h4>{{ $t("pages.property_detail.services_prop") }} </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in prop.developmentServices"
                          :index="i"
                          :key="i"
                          class="enabled"
                        >
                          {{ item }}
                        </li>
                        <li
                          v-for="(item, i) in prop.services"
                          :index="i"
                          :key="i"
                          class="enabled"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div> -->
              <br />
              <!-- VIDEO -->
              <div v-if="prop.video">
                <div class="col-md-12 no-gutters mt-3">
                  <div class="col-12">
                    <h2 class="text-center mb-5">
                      {{ $t("pages.property_detail.video") }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <iframe
                      width="100%"
                      height="315"
                      :src="prop.video"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
              
            </div>

            <div class=" mt-0 col-sm-4">
              <!-- CONTACT -->
               
                  
                  <div class="sidebar ">
                    <div class="p-5 bg-white agentcont">
                      <h2 class=" sid author"><b>{{prop.agent.name}}</b></h2> <hr>

                        <p class="mb-0 text-left  sid border-none">
                          <a
                            class=""
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Contactar en WhatsApp"
                            target="_blank"
                            :href="
                              `https://api.whatsapp.com/send?text=${$t(
                                'pages.property_detail.share_whats_message_agent'
                              )}: ${url}&phone=52${prop.agent.cellphone}`
                            "
                          >
                          <i class="fa fa-phone" style=" text-align: left !important;"></i> {{ prop.agent.cellphone }}
                          </a>
                        </p>
                        <p class="mb-4 text-left  sid">
                              <a
                    class="contact-links"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Contáctenos"
                    :href="'mailto:' + prop.agent.email"
                    target="_blank"
                  ><i class="fa fa-envelope" style=" text-align: left !important;"></i> : {{ prop.agent.email }}</a>
                        </p>
                        <ContactForm />
                    </div>
                   
                  </div>
                  


          
            </div>

            <div class="col-sm-12 mt-5">
                  <div class="container wow zoomIn mb-0">
                <h1 class="section-title mb-0">ENCUENTRA EL HOGAR<span style="color:#cc2922;"> DE TUS SUEÑOS</span></h1>  
            </div>
              <SearchForm/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
import DetailCarousel2 from "@/components/carousel/DetailCarousel2.vue";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
import SearchFormVer from "@/components/search/SearchFormVer.vue";

       
          
    
export default {
  components: {
    Banner,
    Preloader,
    GoogleMap,
    SearchForm,
    ContactForm,
    DetailCarousel,
     DetailCarousel2,
  
 SearchFormVer
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      prop: "_getProperty",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.prop.bestprice[0];
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");
      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;
    if (this.$route.params.agentId) {
      this.agentId = this.$route.params.agentId;
    }
    let data = {
      folio: this.folio,
      agent: this.agentId,
    };
    this.$store.dispatch("getProperty", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
    //Send Data to Access
    if (process.env.NODE_ENV === "production") {
      this.$getLocation().then((coordinates) => {
        let accessData = {
          lat: coordinates.lat,
          lng: coordinates.lng,
          url: this.url,
          propertyId: this.folio,
        };
        this.$store.dispatch("setPropertyClick", accessData);
      });
    }
    this.sliderStyle();
  },
  methods: {
    sliderStyle() {
      setTimeout(function() {
        /*----------------------------------------------------*/
        /*  owlCarousel
					/*----------------------------------------------------*/
        if ($(".nonloop-block-13").length > 0) {
          $(".nonloop-block-13").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            autoplay: true,
            margin: 20,
            nav: false,
            dots: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                margin: 20,
                stagePadding: 0,
                items: 1,
              },
              1000: {
                margin: 20,
                stagePadding: 0,
                items: 2,
              },
              1200: {
                margin: 20,
                stagePadding: 0,
                items: 3,
              },
            },
          });
        }
        if ($(".slide-one-item").length > 0) {
          $(".slide-one-item").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            margin: 0,
            autoplay: true,
            pauseOnHover: false,
            nav: true,
            animateOut: "fadeOut",
            animateIn: "fadeIn",
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
          });
        }
        if ($(".nonloop-block-4").length > 0) {
          $(".nonloop-block-4").owlCarousel({
            center: true,
            items: 1,
            loop: false,
            margin: 10,
            nav: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                items: 1,
              },
            },
          });
        }
      }, 400);
      return;
    },
    
            // nextImage() {
    
            //     var active = this.activeImage + 1;
    
            //     if (active >= this.photos.length) {
    
            //         active = 0;
    
            //     }
    
            //     this.activateImage(active);
    
            // },
    
            // // Go backwards on the images array 
    
            // // or go at the last image
    
            // prevImage() {
    
            //     var active = this.activeImage - 1;
    
            //     if (active < 0) {
    
            //         active = this.photos.length - 1;
    
            //     }
    
            //     this.activateImage(active);
    
            // },
    
            // activateImage(imageIndex) {
    
            //     this.activeImage = imageIndex;
    
            // },      
  },
};
</script>

<style scoped>
.agentcont{
  border-radius: 4px;
box-shadow: 0 0 5px #d2d2d2;
}
.sid{
  padding-left: 5rem;
}
.sid a{
  color:#337ab7!important;
}
.sid a:hover{
  color:#23527c;
  text-decoration: none;
}
.author{
  color:#cc2922 !important;
}
.for-ul {
  column-count: 3 !important;
  /* border-bottom:  solid 1px #a7a7a7 ; */
  padding-bottom: 15px;
}

.mb-5 {
  margin-bottom: 20px;
}
.mt-5 {
  margin-top: 20px;
}

.section-title,
.section-highlight {
  padding-bottom: 15px;
  position: relative;
  text-align: center;
  clear: both;
  display: inline-block;
  width: 100%;
}

.section-title,
.section-highlight {
  margin-bottom: 20px;
  margin-top: 30px;
}

.share-wraper {
  border: 1px solid #e3e3e3;
  margin-bottom: 0px !important;
  padding: 0px 18px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
}

.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0 !important;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  /* try */
}
.banners {
  float: left;
}

.title-bf{
   color: #4d4f52;
   margin-top: 10px;
   margin-bottom: 0;
}

.banner-button {
  display: block;
  width: auto;
  border-radius: 5px;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  border: 1px solid #919191;

  color: #4d4f52;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  background-color: #f1f3f6;
display: block;
color: #000;
width: 100%;
border: 2px solid #000;
border-radius: 4px;
padding: 10px;
margin: 5px !important;
}

.banner-button:hover {
  border: 2px solid #d79b2b;
border-radius: 4px;
padding: 10px;
margin: 5px !important;

  color: #d79b2b;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}

.not-price {
  color: #616161 !important;
  text-decoration: line-through;
}

strong {
  color: #74777c !important;
  font-size: 36px;
  font-weight: 400;
}

.mt-0 {
  margin-top: -5px;
}

.folio {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #253c86;
}

.first {
  margin-bottom: 0 !important;
  margin-top: 0px !important;
}

.not-price{
  font-size: 20px;
}
.property-tit3le{
  font-weight: 500!important;
  font-size: 2rem !important;
  margin-bottom:2rem;
}
.detailsdd{
  list-style: none;
  font-family: 'Montserrat';
}
.detailsdd i{
  color: #000;
}
 ul.detailsdd{
  padding-left:0px !important;
  margin-top: 2rem;
}
.detailsdd li{
  margin-bottom:2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif !important;
  color: #141313!important;
  font-weight: 300 !important;
  margin: 0 !important;
  /*text-rendering: optimizelegibility;*/
}

.mt-title{
  margin-top: 40px !important;
}

.property-title {
    text-transform: none !important;
    font-size: 24pt !important;
    margin-bottom: 40px !important;
    margin-top: 54px !important; 
}

.sidebar.gray .section-title {
    color: #4d4f52 !important;
    margin-bottom: 20px !important;
    margin-top: 10px !important;
}

.buttons-content, .social_areaProp ul{
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  list-style: none !important;
}

.buttons-content li, .social_areaProp li{
  float: left;
margin-left: 5px;
}
.fa-facebook{
  color:#3b5998;
  font-size: 20px !important;
}
.fa-twitter{
color:#1da1f2;
font-size: 20px !important;
}
.fa-linkedin{
color:#0b7bb5;
font-size: 20px !important;
}
.fa-google-plus{
color:#d53824;
font-size: 20px !important;
}
.fa-pinterest{
  color:#cb1f25;
  font-size: 20px !important;
}
.fa-whatsapp{
  color:#000;
}
.tab-content > .active {
  background-color: transparent;
  border: none;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: medium;
  border-top: none;
  padding: 25px 15px;
}
.description p {
  color: #fff;
  text-align: justify !important;
}
.btn-descrip {
  background-color:#173270;
  font-size:15px;
  color: #fff;
padding: 10px 15px !important;
  height:50px;
}
.btn-descrip {
  background-color:#173270;
  font-size:15px;
  color: #fff;

  
}
.btn-descrip:active, .btn-descrip:focus{
  background-color: #cc2922;
  color: #fff !important;
}
pre{
  white-space: pre-wrap;
height: auto;
overflow: hidden;
background: transparent;
border-color: transparent;
color: #000;
font-family: Open Sans,sans-serif;
font-size: 15px;
text-align: justify;
}
.property-single-item.property-main {
  padding: 10px 0px;
}
.property-single-item {
  margin-bottom: 45px;
  background: white;
  padding: 30px;
}
.form-control{
  background: #fdfdfd!important;
  border: 1px solid #ececec!important;
  color: #999!important;
  font-family: 'Montserrat',sans-serif!important;
}
.social_areaProp li{
  margin-right: 3rem;
  margin-top:1.5rem;
}
.social_areaProp span{
  font-family: 'Montserrat',sans-serif!important;
}
.price-here{
  background-color: #ededed;
  border-radius: 5px;
  width: 50%;
  height: auto;
  text-align: center;
}
.price-here h3{
  color: #000;
padding-top: 10px;
padding-bottom: 10px;
font-size:22px !important;
text-transform: uppercase;
}
.price-here #leyendaPrecios{
  float: right;
  font-size: 11px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
  padding-bottom: 10px;
}
</style>
