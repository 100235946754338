<template>
    <div class="col-md-4 agency-detail clearfix ">
        <div class="info text-center">
            <!-- <img v-if="agent.image" class="img" :src="agent.image ? agent.image : '/images/avatar2.png'"
            :alt="`${agent.fullname}-img`" /> -->
             <img v-if="agent.image!=0" :src="agent.image" :alt="`${agent.fullname}-img`" />
            <img v-else src='/images/avatar2.png' :alt="`${agent.fullname}-img`" />
            <header>
                <h2> <small>{{agent.fullname}} </small></h2>
            </header>
            <!-- <ul class="contact-us">
                <li>
                    <i class="fa fa-envelope"></i>
                    <a v-if="agent.email" :href="'mailto:' + agent.email" target="_blank">{{agent.email}}</a>
                </li>
                <li>
                    <i class="fa fa-phone"></i> 
                    <a v-if="agent.cellphone" :href="'tel:' + agent.cellphone" target="_blank">{{agent.cellphone}}</a> 
                </li>
            </ul> -->
            <ul class="social-networks">
                <!--
                <li v-if="agent.fb"><a :href="agent.fb"><i class="fa fa-facebook"></i></a></li>
                <li v-if="agent.tweet"><a :href="agent.tweet"><i class="fa fa-twitter"></i></a></li>
                <li v-if="agent.instagram"><a :href="agent.instagram"><i class="fa fa-instagram"></i></a></li>
                <li v-if="agent.gmas"><a :href="agent.gmas"><i class="fa fa-google-plus"></i></a></li>
                <li v-if="agent.linkedin"><a :href="agent.linkedin"><i class="fa fa-linkedin"></i></a></li>
                -->
                <!-- <li ><a :href="agent.fb"><i class="fab fa-facebook"></i></a></li>
                <li ><a :href="agent.tweet"><i class="fab fa-twitter"></i></a></li>
                <li ><a :href="agent.instagram"><i class="fab fa-instagram"></i></a></li>
                <li ><a :href="agent.gmas"><i class="fab fa-google-plus"></i></a></li>
                <li ><a :href="agent.linkedin"><i class="fab fa-linkedin"></i></a></li> -->

            </ul>
        </div>
    </div>
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    agent: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formatBiography() {
      return this.agent.biografia.substring(0, 125) + "...";
    },
  },
  methods: {
    goToAgent() {
      //this.$store.dispatch('setAgentActive',this.agent);

      let nameAgentFilt = services.normalize(this.agent.fullname);
      let folio = this.agent.folio;
      this.$router.push({
        name: "Agent",
        params: {
          folio,
          nameAgentFilt,
        },
      });
    },
  },
};
</script>

<style scoped>
.staff img{
    object-fit: contain;
    object-position: center;
    width: 60%;
}
.agency-detail img{
  width: 60%;
   object-fit: contain;
    object-position: center;
}
p,a{
    overflow-wrap: break-word;
}
a{
    cursor: pointer;
}
</style>
