<template>
    <div class="main content mb-5 mt-5">
        <div class="container">
            <div class="row">
              <div class="container wow zoomIn mb-0">
                <h1 class="section-title mb-0">ENCUENTRA EL HOGAR<span style="color:#cc2922;"> DE TUS SUEÑOS</span></h1>  
              </div>
              <SearchForm/>
                <div id="property-listing" class="list-style clearfix col-md-12 mt-5" >
                  <!-- {{this.total}} -->  
                    <div class="row justify-content-center">
                        <PropertyCardHor
                        v-for="(item, i) in properties"
                        :key="item.folio"
                        :name="item.folio"
                        :index="i"
                        :item="item"
                       
                      />
                    </div>
                    	<!-- Paginator -->
                      <!-- <div class="row">
                        <div class="col-md-12">
                          <div class="pagination_area">
                            <nav aria-label="Page navigation">
                              <ul class="pagination pagination_edit">
                                <li v-show="pag != 1"  id="previous">
                                  <a href="#" aria-label="Previous" @click="changePage(  pag -= 1)">													
                                    <span aria-hidden="true"><i class="fas fa-chevron-left"></i></span>
                                  </a>
                                </li>
                                <li v-for="(n) in NUM_PAGES" :key="n" :id="n" @click="changePage( pag = n)" v-bind:class="{'active':(pag==n)}">
                                  <a aria-hidden="true" href="#">{{n}}</a>
                                </li>
                                <li v-show="pag * NUM_RESULTS / this.total < 1" id="next">
                                  <a href="#" aria-label="Next" @click="changePage(  pag+=1 )" >
                                    <span aria-hidden="true"><i class="fas fa-chevron-right"></i></span>													
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div> -->
                      <!--End paginator -->
                  <div class="pagination-row col-sm-12 text-center mt-3">
                    <a  v-if="pagina != 1"
                      @click="changePageNumber( pagina - 1)" class="arrow-pag"><i class="fas fa-chevron-left"></i></a>
                    <span v-for="(item , index) of pages" :key="index">
                        <a v-if="(pagina - (inter_pg - index)) > 0 && index < total_pg" 
                        v-on:click.prevent="changePageNumber(pagina - (inter_pg - index))" 
                        :class="[(pagina - (inter_pg - index) == pagina ) ? 'active' : null, 'page ']" :id="`page${index}`">

                        {{pagina - (inter_pg - index)}} </a>  
                    </span>
                    <a  v-if="pagina != lastPage"
                    @click="changePageNumber(  ++pagina )" class="arrow-pag"><i class="fas fa-chevron-right"></i></a>
                  </div>
                  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import PropertyCardHor from "@/components/properties/PropertyCardHor.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import VuePaginate from 'vue-paginate'
export default {
  components: {
    PropertyCard,
    SearchForm,
    PropertyCardHor,
    VuePaginate
  },
  data() {
    return {
      codeShareProperties: 0,
      pag:1,
      pagina: this.$route.query.pagina,
     // paginas a mostrar:
      total_pg: 5,
      inter_pg: 2,
      pagesAux: [],
      lastPage: 0,
    };
  },
  computed: {
    ...mapGetters({
      properties: "_getProperties",
      pages: "_getPages",
      totalPag: "_getTotalPag",
    }),
  },

  created() {
    if (this.$route.params.codeShareProperties) {
      this.codeShareProperties = this.$route.params.codeShareProperties;
    }
    // console.log('paginadeluser', this.$route.query.pagina)
    var currentPage;
    currentPage =  this.$route.query.pagina;
    this.$store.dispatch("getProperties", {'codeShareProperties' :this.codeShareProperties, 'numeroPaginas':currentPage, 'offset':6});
    var $this = this;
      $this.pagesAux = $this.pages;
      //declarando el num de la ultima pagina
      this.lastPage = this.totalPag;
     // console.log('ULTIMAPAGINA', this.lastPage)

      const numPage = this.$route.query.pagina;
        if ( numPage ) {
                this.pagina  = Number( numPage )
            }
    
      },
  methods: {
    changePageNumber(numPage){	
         this.pagina = numPage
          this.$router.push({
              path: "/propiedades",
              query: {
                  pagina: this.pagina,
              }
          })
          //  this.$store.dispatch("getProperties");
          var currentPage;
          currentPage =  this.$route.query.pagina;
          this.$store.dispatch("getProperties", {'codeShareProperties' :this.codeShareProperties, 'numeroPaginas':currentPage, 'offset':6});
          var $this = this;
            $this.pagesAux = $this.pages;
        //para que suba cada vez que de a una nueva pagina
        window.scroll(0,0)
        //Eliminar las ultimas paginas cuando llega al final
        if ( this.pagina == this.lastPage){
            $("#page3").hide();
            $("#page4").hide();
            //para testear 
            // alert('iguales', )
            // console.log('butterflies',this.pagesAux[3])
        } 
        else{
          $("#page3").show();
          $("#page4").hide();
        }
    },
  }
};
</script>
<style scoped>
.pagination-row a.active {
  background-color: #d75954;
  border-color: transparent;
  color: #fff;
}
.pagination-row a.arrow-pag{
  color:#d75954;
}
.pagination-row a.active:hover {
  background-color: #d75954;
  border-color: transparent;
  color: #fff;
}
@keyframes animate{
  0%{
    box-shadow: 0 0 0 0 rgba(0, 129, 209, 0.7);
  }
  40%{
    box-shadow: 0 0 0 20px rgba(0,206,209, 0);
  }
  80%{
    box-shadow: 0 0 0 20px rgba(0,206,209, 0);
  }
  100%{
    box-shadow: 0 0 0 rgba(0,206,209, 0);
  }
}
.pagination-row a{
  border-radius: 0.475rem;
  height: 2.5rem;
  min-width: 2.5rem;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.375rem 0.75rem;
  border: 0 solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  font-weight: bold;
  color:#5E6278;
  cursor:pointer;
}
.pagination-row a:hover{
  background-color: #F1FAFF;
  border-color: transparent;
  transition: color 0.2s ease, background-color 0.2s ease;
  color:#d75954;
}
</style>