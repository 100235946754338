<template>
  <div class="item col-sm-4">
    <div class="image">		
      <a @click="goToProperty(item.folio, item.namePropertyEs)" class="btn ">
      </a>	
       <a style="color:rgb(54, 52, 53); cursor:pointer;" @click="goToProperty(item.folio, item.namePropertyEs)">
      <img :src="item.smallphoto" />
      </a>
    </div>
    <div class="price">
        {{item.operation}}
    </div>
    <div class="infomain">
      <a style="color:rgb(54, 52, 53); cursor:pointer;" @click="goToProperty(item.folio, item.namePropertyEs)">

        <h5 class="prop-title"> {{item.namePropertyEs}} </h5>
      </a>
      
      <div class="loca">
          <span> <i class="fa fa-map-marker"></i> <span v-if="item.colony">{{item.colony}},</span> {{item.city}} </span>
      </div>
    </div>
    
    <ul class="amenities">
              <li v-if="item.m2c != 0"> Construcción <br>
                {{ item.m2c }} m<sup>2</sup>
              </li>
               <li v-else-if="item.mlot != 0"> Terreno <br>
                {{ item.mlot }} m<sup>2</sup>
              </li> 
              <li v-if="item.bedrooms!= 0" > Dormitorios <br>
                {{ item.bedrooms }}
              </li>
              <li v-if="item.bathrooms!= 0" > Baños <br>
                {{ item.bathrooms }}
              </li>
    </ul>
      <div  class="bed_area">
        <ul >
          <li >
              <span v-if="item.prices.sold!='0'">
              {{ item.prices.sold }} 
            </span>
            <span v-else-if="item.prices.opportunity_rent !='0'">
              {{ item.prices.opportunity_rent }} {{ item.currennamePerMonth }}
            </span>
            <span v-else-if="item.prices.rent !='0'">
              {{ item.prices.rent }} {{ item.currennamePerMonth }}
            </span>
            <span v-else-if="item.prices.vacation_rent !='0'">
              {{ item.prices.vacation_rent }} {{ item.vacation_rental_currency }}
            </span>
          </li>
            <li id="leyendaPrecios">
            <small id="leyendaPrecios"> *PRECIOS SUJETOS A CAMBIOS SIN PREVIO AVISO</small>
          </li>
        </ul>
      </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import services from "@/store/_services";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mainPrice: 0,
      secondPrice: 0,
      mainOperation: 0,
      secondOperation: 0,
    };
  },
  computed: {
    ...mapGetters({
      info:'_getInfoCompany',
    }),
    priceInfo() {
      //Formateo de decimales
      let decimalPrice = this.mainPrice.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = this.mainPrice.indexOf("MXN");
      let currentPriceU = this.mainPrice.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = this.mainPrice.substr(1, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += this.mainPrice.substr(currentPriceM);
      } else {
        formatPrice += this.mainPrice.substr(currentPriceU);
      }
      return formatPrice;
    },
  },
  
  methods: {
    goToProperty() {
      let namePropFilt = services.normalize(this.item.namePropertyEs);
      let folio = this.item.folio;
      this.$router.push({
        name: "Property",
        params: {
          folio,
          namePropFilt,
        },
      });
    },
  },
  created() {
    /* For Base Template*/
    let prices = this.item.prices;
    let operations = this.item.operations;
    if (prices.opportunity_sold) {
      this.mainPrice = prices.opportunity_sold;
      this.mainOperation = operations.opportunity_sold;
      this.secondPrice = prices.sold;
      this.secondOperation = operations.sold;
    } else if (prices.opportunity_rent) {
      this.mainPrice = prices.opportunity_rent;
      this.mainOperation = operations.opportunity_rent;
      this.secondPrice = prices.rent;
      this.secondOperation = operations.rent;
    } else if (prices.sold) {
      this.mainPrice = prices.sold;
      this.mainOperation = operations.sold;
    } else if (prices.rent) {
      this.mainPrice = prices.rent;
      this.mainOperation = operations.rent;
    }
  },
};
</script>

<style scoped>
 img {
    height: 212px !important;
  }

  .image{
    object-fit: contain;
  }
  @media (max-width: 991px) {
    #contain_tarjet_property{
      width:70%;
    }
  }
   @media (max-width: 520px) {
    #contain_tarjet_property{
      width:100%;
    }
   }

   h3{
     font-size: 15px !important;
   }

  .name-prop{
    white-space: pre-wrap;
  }
       .bed_area{
      background-color: #ffffff;
     }
     .bed_area {
    height: 50px;
    color: #000;
    border-top: 1px solid #e5e5e5;
    -moz-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
.bed_area li{
  list-style: none;
}
#leyendaPrecios {
  font-size:8.5px;
}
.amenities{
  font-size:12px;
  padding:6px 27px !important;
  text-align: left !important;
}
.loca i{
  color: #000;
}
.loca span{
  color: rgb(115, 115, 115);
  font-size: 12px;
}
.prop-title{
  overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
line-height: 30px;
}
.infomain{
  background-color: #fff;
  padding: 10px 10px 1px 23px;
}
.grid-style1 .item .image > a{
position: inherit !important;
top: inherit !important;
left: inherit !important;
background-color: inherit !important;
border: inherit !important;
opacity: 1!important;
visibility: inherit !important;
text-decoration: none;
filter: 1;
-webkit-opacity: 1;
-moz-opacity: 1;
}
</style>