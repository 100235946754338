<template>
  <section>
        
        <VueAgile ref="main" :options="options1" :as-nav-for="asNavFor1" class="contain-slide main" >
            <div v-for="(slide,i) in items" :key="slide.folio" :index="i" :class="`slide slide--${i}`">
                <a class="example-image-link" :href="slide.largefile" data-lightbox="example-set" :data-title="slide.alt">
                    <img  class="card-img " :src="slide.largefile" :alt="slide.alt"/>
                </a>
            </div>

        </VueAgile>
        <VueAgile  ref="thumbnails" :options="options2" :as-nav-for="asNavFor2" class="contain-slide thumbnails" >
            <div v-for="(slide,i) in items" :key="slide.folio" :index="i" :class="`slide slide--thumbniail slide--${i}`" @click="$refs.thumbnails.goTo(i)">
                <img  class="card-img " :src="slide.largefile" :alt="slide.alt"/>
            </div>

            <template slot="prevButton"><i class="fa fa-chevron-left"></i></template>
            <template slot="nextButton"><i class="fa fa-chevron-right"></i></template>
        </VueAgile>


        
        <!--PageLoader v-else/-->
    </section>
</template>

<script>
import { VueAgile } from 'vue-agile';
//import PageLoader from '@/components/PageLoader';
import services from '@/store/_services';
export default {
    components: {
        VueAgile,
        //PageLoader
    },
    props:{
        items:{
            type:Array,
            required:true
        }
    },
    data(){
        return{
            asNavFor1: [],
            asNavFor2: [],
            options1: {
				dots: false,
				fade: true,
				navButtons: false
			},
			
			options2: {
				autoplay: true,
				centerMode: true,
				dots: false,
				navButtons: true,
				slidesToShow: 3,
				responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 5
                        }
                    },
                    
                    {
                        breakpoint: 1000,
                        settings: {
                            navButtons: true
                        }
                    }
                ]
            }
        }
    },
    methods:{
        goToProperty(folio,namePropertyEs){
            let namePropFilt=services.normalize(namePropertyEs);
            this.$router.push({
                name: 'Property',
                params: {
                    folio,
                    namePropFilt
                }
            });
        }
    },
    mounted(){
        this.origin=this.$route.name;
        this.asNavFor1.push(this.$refs.thumbnails)
        this.asNavFor2.push(this.$refs.main)
    },
    beforeUpdate(){
    
        this.$refs.main.reload();
        this.$refs.thumbnails.reload();
    }
}
</script>

<style >
.main {
  padding-top:0px;
  padding-bottom: 0px;
}
    .agile__actions{
    
        margin-top: 19px;
        /* color: #fff; */
        margin-left: 0px;
        margin-right: 0px;
    }
    .main .slide img{
       -o-object-position: center;
        -o-object-fit: contain;
        object-fit: cover;
        height: 600px;
        width: 100%;
    }
    .agile__nav-button{
        background: transparent;
		border: none;
		color: #d79b2b;
		cursor: pointer;
		font-size: 24px;
        transition-duration: .3s;
        margin-top: -15%;
    }
    .agile__nav-button--prev{
        margin-left: -20px;
    }
    .agile__nav-button--next{
        margin-right: -20px;
    }
    .thumbnails{
        margin-bottom: 0px ;
        width: 100%;
    }
   
    .slide{
        align-items: center;
        box-sizing: border-box;
        color: #fff;
        display: flex;
        height: auto;
        justify-content: center;
    }
    .slide .slide--thumbniail{
        width: 200px;
        padding: 10px;
        height: auto;
    }
    .slide--thumbniail img{
		  height: 100px;
            object-fit: cover;
            object-position: center;
            width: 95%;
            margin-top: 1.5rem;
    }
    
</style>