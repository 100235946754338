<template>
<!-- BEGIN PAGE TITLE/BREADCRUMB -->
<div class="parallax colored-bg pattern-bg" data-stellar-background-ratio="0.5">
<div class="container">
    <div class="row">
        <div class="col-sm-12 bred">
            <h1 class="page-title">{{$t(`${pageTitle}`)}}</h1>
            
            <ul class="breadcrumb">
                <li><router-link to="/">{{ $t("routes.home.breadcrumb") }} </router-link></li>
                <li><a href="#">{{$t(`${pageTitle}`)}}</a></li>
            </ul>
        </div>
    </div>
</div>
</div>
<!-- END PAGE TITLE/BREADCRUMB -->
</template>


<script>
export default {
  props: {
    image:{
      type: String,
      require: true
    },
    pageTitle:{ type:String, required:true}
  },
};
</script>

<style scoped>
.breadcrumb{
  background-color: transparent !important;
  float: right;
  text-transform: capitalize!important;
  line-height: 24px;
  font-family: 'Montserrat';
} 
.page-title{
  font-family: 'Montserrat';
  display: inline-block;
  float: left;
}
.bred{
  display:table;
  width: 100%;
}
</style>