<template>
  <div>
    <section class="wrapper">
      <div class="container">
        <div class="row">
            <div class="container wow zoomIn mb-0">
                <h1 class="section-title mb-0">ENCUENTRA EL HOGAR<span style="color:#cc2922;"> DE TUS SUEÑOS</span></h1>  
            </div>
              <SearchForm/>
          <div class="main col-sm-6 col-sm-offset-8 center">
            <div class="text-center mb-2 mt-5">
             <h1 class="cus">CONTACTANOS</h1>
            </div>
            <ContactForm2 />
          </div>


        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactForm2 from "@/components/layout/ContactForm2.vue";
import Banner from "@/components/layout/Banner.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "app",
  components: {
    Banner,
    SearchForm,
    ContactForm2,
  },
  data() {
    return {
      contact: true,
    };
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>


<style scoped>
.cus{
  font-family:'Montserrat';
}
.form-control{
  padding: 12px 20px!important;;
  border: 1px solid #cc2922 !important;
}
.logo {
  display: inline-block;
  max-width: 10%;
  margin-top: auto;
  margin-bottom: 20px;
}

.logo img {
  margin-top: 40%;
  margin-left: -10px;
  max-width: 20px;
  max-height: 30px;
}

.color-grey{
  color: #808080;
}

@media (max-width: 980px){
  .logo{
    max-width: 22%;
  }  
} 
</style>