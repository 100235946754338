<template>
    <div id="home-advanced-search" class="gray open" style="background-color:transparent !important;">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <form v-on:submit.prevent="searchProps">
                        <div class="form-group">
                            <!-- KEYWORD -->
                            <div v-if="!search.searchDevelopments" class="form-control-large mb-3">
                                <input
                                    v-model="search.keyword"
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t('layout.search_form.keyword_input')"
                                />
                            </div>

                            <!-- PROPERTY TYPE -->
                            <div class="form-control-large select-type">
                                <select name="select-type" class="form-control" id="select-type" v-model="search.type">
                                    <option selected value=""> {{$t(`layout.search_form.${!search.searchDevelopments
                                                                ? "property_type_input"
                                                                : "development_type_input"}`)}} 
                                    </option>
                                    <option v-for="(type, i) in !search.searchDevelopments  
                                                ? input.types 
                                                : input.typesDevs"
                                            :key="i"
                                            :index="i"
                                            :value="type.typepropertyid">
                                        {{type.typepropertyspa}}
                                    </option>

                                </select>

                            </div>

                            <!-- STATUS -->
                            <div v-if="!search.searchDevelopments" class="form-control-large select-type">
                                <select class="form-control " name="select-city" id="select-city" v-model="search.operation">
                                    <option selected value="">
                                        {{$t("layout.search_form.operation_input")}}
                                    </option>
                                    <option value="1">
                                        {{ $t("layout.search_form.option_sold") }}
                                    </option>
                                    <option value="2">
                                        {{ $t("layout.search_form.option_rent") }}
                                    </option>
                                    <option value="3">
                                        {{ $t("layout.search_form.option_vacation") }}
                                    </option>
                                </select>

                            </div>

                           
                        </div>
                         <!-- BUTTON -->
                            <button type="submit" class="btn btn-fullcolor">
                                {{ $t("layout.search_form.button") }}
                            </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
export default {
  components: {
    Money,
  },
  data() {
    return {
      search: {
        operation: "",
        type: "",
        typeDevs: "",
        city: "",
        keyword: "",
        minPrice: "",
        maxPrice: "",
        currency: "",
        urbanization: "",
        beds: "",
        baths: "",
        parking: "",
        searchDevelopments: false,
      },
      searching: false,
      moneyMin: {
        thousand: ",",
        prefix: "$",
        precision: 0,
        masked: false,
      },
      moneyMax: {
        thousand: ",",
        prefix: "$",
        precision: 0,
        masked: false,
      },
      advanced: false,
    };
  },
  computed: {
    ...mapGetters({
      input: "_getInputs",
    }),
  },
  methods: {
    searchProps() {
      this.searching = true;
      let searchForm = this.search;
      this.$store.dispatch("getResults", searchForm);

      if (!this.search.searchDevelopments) {
        if (this.$route.name != "Results")
          this.$router.push({ name: "Results" });
      } else {
        if (this.$route.name != "ResultsDevs")
          this.$router.push({ name: "ResultsDevs" });
      }

      this.searching = false;
    },
    advancedChange() {
      let temp = this.advanced;
      this.advanced = !temp;
    },
    changeForm() {
      for (let i in this.search) {
        if (i != "searchDevelopments") this.search[i] = "";
      }
    },
  },
  created() {
    this.$store.dispatch("getInputs");
    console.log(this.$route.name);
    if (
      this.$route.name == "Developments" ||
      this.$route.name == "ResultsDevs"
    ) {
      this.search.searchDevelopments = true;
    }
  },
};
</script>

<style scoped>
.switchBtn {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 34px;
}
.switchBtn input {display:none;}
.slide {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    padding: 8px;
    color: #fff;
}
.slide:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 5px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slide {
    background-color: #ef4b4a;
    padding-left: 40px;
}
input:focus + .slide {
    box-shadow: 0 0 1px #01aeed;
}
input:checked + .slide:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: 10px;
}

.label-switch {
  margin-right: 30px;
  font-weight: 300 !important;
  margin-bottom: 0 !important;
}

.slide.round {
    border-radius: 34px;
}
.slide.round:before {
    border-radius: 50%;
}

.form-search-detail{
  width: auto;
  overflow: hidden;


}

/* .form-control {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: none;
    border-color: #E4E4E4;
    color: #74777c;
    height: 41px !important;
    margin-bottom: 10px;
    font-size: 14px;
} */
.form-control {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: none;
  border-color: #908c8c;
  color: #74777c;
  height: 41px !important;
  margin-bottom: 10px;
  margin-bottom: 10px;
  width: 300px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
    border-top-color: rgb(204, 204, 204);
    border-right-color: rgb(204, 204, 204);
    border-bottom-color: rgb(204, 204, 204);
    border-left-color: rgb(204, 204, 204);
    transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-size: 14px;
  line-height: 1.42857143;
}
.select-type select, .form-control-small select, .form-control-large select, .form-control-large input{
  background-color: #fff !important;
  color: #74777c !important;
  font-size: 16px !important;
  padding: .5em !important;
    padding-right: 0.5em;
  padding-right: 2.5em !important;
  border: 1px solid #74777c !important;
  margin: 0 !important;
  border-radius: 5px !important;
  text-indent: .01px !important;
  text-overflow: "" !important;
  -webkit-appearance: button !important;
  width: 300px !important;
}
.select-type{
  position: relative !important;
  display: inline-block !important;
  vertical-align: middle !important;
  margin-bottom: 10px !important;
  width: 300px;
}
/* .select-type::before {
  background-color: #d79b2b !important;
}
.select-type::before {
  width: 2em !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  border-radius: 0 3px 3px 0 !important;
}
.select-type::after, .select-type::before {
  content: "" !important;
  position: absolute !important;
  pointer-events: none !important;
}
.select-type::after {
  color: rgba(0,0,0,.4) !important;
}
.select-type::after {
  content: "\25BC" !important;
  height: 1em;
  font-size: .625em !important;
  line-height: 1 !important;
  right: 1.2em !important;
  top: 50% !important;
  margin-top: -.5em !important;
} */
/* .select-type::after {
  content: "\e5c5";
  font-family: icomoon !important;
  font-size: 24px;
  position: absolute;
  right: 2px;
  top: 0;
  z-index: 1;
  width: 9%;
  height: 100%;
  pointer-events: none;
  background-color: #00448b;
  color: #fff;
  border-bottom-right-radius: .75rem;
  border-top-right-radius: .75rem;
} */
 input[type="text"] {
  margin-bottom: 10px;
  width: 300px;
}
@media (max-width: 980px){
  .form-control-small-responsive{
    margin-bottom: 25px;
  }

  .label-switch-responsive{
    top: 10px;
  }
}  

.form-control-large, .form-control-small {
    display: inline-block !important;
} 
</style>