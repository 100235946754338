<template>
    <div class="content">
			<div class="container">
				<div class="row">
				
					<!-- BEGIN MAIN CONTENT -->
					<div class=" col-sm-12">
						<div class="e404 clearfix">
							<div>
								<strong><span v-for="(number, i) in splitCode" :key="number" :index="i">{{number}}</span></strong>
								
							</div>
						</div>
					</div>	
					<!-- END MAIN CONTENT -->
					
					<!-- BEGIN SIDEBAR -->
					<div class="sidebar col-sm-12 ">
						
						<div id="e404-side">
							<h3>{{$t(`components.error_section.${code}.title`)}}</h3>
							
							<p><br/><br/>La página que busca no parece existir. <br>
Regrese o diríjase a nuestra página principal para elegir una nueva dirección.</p>
						
							<p class="center"><br/><a href="/" class="btn btn-default-color">{{$t("layout.not_found.go_home")}}</a></p>
						</div>
					</div>
					<!-- END SIDEBAR -->
					
				</div>
			</div>
		</div>
</template>

<script>
export default {
  props:{
    code:{
      typeo:String,
      require:true
    }
  },
  computed:{
    splitCode(){
      return this.code.split("")
    }
  }
}
</script>
<style scoped>
.btn-default-color{
	color: #333!important;
background-color: #fff!important;
border:none;
}

</style>