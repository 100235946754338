<template>
  <div class="main content mb-5">
    <NotResults v-if="error == 204" code="204" />
    <div v-else class="container">
      <Preloader v-if="!results" />
      <div v-else>
        <div class="row">
          <div id="property-listing" class="grid-style1 clearfix col-md-12 mt-4">
            <h1 class="text-black mb-4 text-center property-title">PROPIEDADES ENCONTRADAS: {{resultados}}  </h1>
                <div class="text-center">
                  <span class="gohome text-center">
                    <router-link to="/">
                      <i class="fa fa-undo"></i> Inicio
                    </router-link>
                  </span>
                </div>
               
            <hr>
            <div class="row justify-content-center">
              <template v-if="!fromDevs">
                <PropertyCard
                  v-for="(item, i) in results"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                  v-show="(pag - 1) * NUM_RESULTS <= i  && pag * NUM_RESULTS > i"
                />
              </template>
              <template v-else>
                <DevelopmentCard
                  v-for="(item, i) in results"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                />
              </template>

            <!-- Paginator -->
               <div class="row">
                        <div class="col-md-12">
                          <div class="pagination_area">
                            <nav aria-label="Page navigation">
                              <ul class="pagination pagination_edit">
                                <li v-show="pag != 1"  id="previous">
                                  <a href="#" aria-label="Previous" @click="changePage(  pag -= 1)">													
                                    <span aria-hidden="true"><i class="fas fa-chevron-left pr-1"></i> Anterior</span>
                                  </a>
                                </li>
                                <li v-for="(n) in NUM_PAGES" :key="n" :id="n" @click="changePage( pag = n)" v-bind:class="{'active':(pag==n)}">
                                  <a aria-hidden="true" href="#">{{n}}</a>
                                </li>
                                <!--<li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>-->
                                <li v-show="pag * NUM_RESULTS / this.resultados < 1" id="next">
                                  <a href="#" aria-label="Next" @click="changePage(  pag+=1 )" >
                                    <!-- @click.prevent="pag += 1" --> 
                                    <span aria-hidden="true"> Siguiente <i class="fas fa-chevron-right pl-1"></i></span>													
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                </div>
                <!--End paginator -->





              <SearchForm/>

               
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import Preloader from "@/components/layout/Preloader.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import DevelopmentCard from "@/components/developments/DevelopmentCard.vue";
import NotResults from "@/components/layout/NotFound";
export default {
  components: {
    SearchForm,
    PropertyCard,
    DevelopmentCard,
    Preloader,
    NotResults,
  },
  data() {
    return {
      codeShareProperties: 0,
      revRoute: null,
      developments: false,
      pag:1,
	  	NUM_RESULTS:6,			
    	// properties: [],
      isLoading: false,
      NUM_PAGES:0,
    };
  },
  computed: {
    ...mapGetters({
      results: "_getResults",
      resultados: "_getResultados",
      error: "_getError",
    }),
    fromDevs() {
      if (this.$route.name == "ResultsDevs") {
        return true;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((self) => {
      self.prevRoute = from;
      if (!self.prevRoute.name) {
        self.$router.push({ name: "Home" });
      }
    });
  },
  created() {

        this.isLoading = true
        this.results = this.$store.dispatch("getResults");
        this.total = this.results.length;
        console.log('totalito', this.results.length)
        this.NUM_PAGES = this.this.results.length/this.NUM_RESULTS;
				this.NUM_PAGES=this.NUM_PAGES.toString();
				this.NUM_PAGES= this.NUM_PAGES.split(".");
				this.NUM_PAGES=eval(this.NUM_PAGES[0]);
				if(this.results.length% this.NUM_RESULTS !=0){
					this.NUM_PAGES++;
      }			
      //declarando el numero de pagina, tomando el valor numérico
        const page = this.$route.query.pagina;
        if ( page ) {
                this.pag = Number( page )
            }
    },
      methods: {
    //enviar el nuevo numero de pagina al url cada que le den clic a un numero o < >
    changePage(page){
          this.pag = page
          this.$router.push({
              path: "/busqueda",
              query: {
                  pagina: this.pag,
              }
          })
        //para que suba cada vez que de a una nueva pagina
        window.scroll(0,0)
        }
  }
};
</script>
<style scoped>
.property-title{
  font-family: 'Montserrat';
  color:#818181 !important;
  font-weight: bold;
}
.gohome{
  text-decoration: none !important;
  text-align: center!important;
}
.gohome a:hover{
  text-decoration: none !important;
  text-align: center!important;
}
.pagination > li > a{
border-radius: 5rem;
/* margin-left: 63rem; */
border: none;
color: #d75954;
font-family: 'Montserrat';
}
#previous{
  margin-right: 49rem;
}
</style>