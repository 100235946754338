<template>
  <header>
    <div id="top-bar">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <ul id="top-info">
              <li>
                    <a
                    class="contact-links text-right cl1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Contáctenos"
                    :href="'mailto:' + info.reserveemail"
                    target="_blank"
                  >
                <i class="fa fa-envelope"></i> {{ info.reserveemail}} </a>
              </li>
              <li class="text-right ml-5">
                  <a
                    class="contact-links text-left"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Contáctenos"
                    :href="'tel:+52' + info.phone"
                    target="_blank"
                  >
                   
                 <i class="fa fa-phone"></i> 
                {{ info.phone }} </a>
              </li>
            </ul>
            <!-- <div id="top-buttons">
              <span><i class="fa fa-globe"></i></span>
              <LocaleSwitcher />
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <nav id="header" class=" navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <router-link to="/" class="nav-logo"
              ><img
                v-if="info"
                class="header-logo m-1"
                :src="info.logo"
                alt="logo_web"
              />
            </router-link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div  class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="nav navbar-nav me-auto ">
                <li class="size">
                  <router-link to="/">{{ $t("layout.nav.home") }}</router-link>
                </li>
                <!-- <li class="size">
                  <router-link to="/nosotros">{{
                    $t("layout.nav.about.title")
                  }}</router-link>
                </li> -->
                <li class="size">
                  <router-link to="/propiedades?pagina=1">{{
                    $t("layout.nav.properties")
                  }}</router-link>
                </li>
                <!-- <li class="size">
                  <router-link to="/desarrollos">{{
                    $t("layout.nav.developments")
                  }}</router-link>
                </li> -->
                
                <li class="size size-last">
                  <router-link to="/contacto">{{
                    $t("layout.nav.contact")
                  }}</router-link>
                </li>
                <!-- <li class="size">
                  <router-link to="/equipo">{{
                    $t("routes.agents.page_title")
                  }}</router-link>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
  components: {
    LocaleSwitcher
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
.navbar {
  position: relative !important ;
  z-index: 1;
  font-size: 14px;
}
.home-logo {
  width: 4vw;
}
@media only screen and (max-width: 991px) {
  .home-logo {
    width: 20%;
  }
}
#top-bar {
  width: 100% !important;
  min-height: 33px !important;
  font-size: 13px !important;
  line-height: 33px !important;
  background-color: #f1f3f6 !important;
  position: relative !important;
  z-index: 1020 !important;
  color: #000 !important;
}

.nav {
  float: right !important;
}


@media only screen and (max-width: 991px) {
  .size{
  height: 40px;
  margin-bottom: 10px;
}

  .size-last{
    margin-bottom: 30px;
  }
}

.navbar-collapse{
  float: right;
}

.navbar-toggler{
  float: right;
  margin: 36px;
}
    .contact-links{
      color: #000 !important;
    }
    .cl1{
      margin-right: 60em;
    }
</style>
